
.color-1 {
  color: #ed0677 !important; }

.color-2 {
  color: #00aeef !important; }

.color-3 {
  color: #003663 !important; }

.color-4 {
  color: #98ca45 !important; }

.bg-color-1 {
  background-color: #ed0677;
  color: white;
  font-size: 30px;
  font-weight: 800;
  font-style: italic; }

.bg-color-2 {
  background-color: #ed0677;
  color: white;
  font-size: 30px;
  font-weight: 800;
  font-style: italic; }

a.bg-color-1:hover, .btn-contato.bg-color-1:hover {
  color: #00aeef; }
a.bg-color-2:hover, .btn-contato.bg-color-2:hover {
  color: #ed0677; }

.circle {
  background-color: #00aeef;
  color: white;
  border-radius: 50%; }
  .circle i {
    font-size: 25px; }

a.circle {
  width: 45px;
  height: 45px;
  display: block;
  text-decoration: none;
  color: white; }
  a.circle:hover {
    color: #ed0677; }

body {
  font-family: 'Nunito', Arial, Helvetica, sans-serif; }

main > .container {
  padding: 60px 15px 0; }


  header {
    background-color: #003663;
    color: white; 
    width: 100%}
    
    header.home {
      background-image: url("./images/bg-home.png");
      background-position: center bottom;
      background-repeat: no-repeat;
      width: 100%; }

      
    header .offcanvas-end {
      background-image: url("./images/bg-menu.png");
      background-color:#003663;
      color: white; }
  
      header .offcanvas-header{
        color: #00aeef;
        font-weight: 700;
        font-size: 48px;
        font-style: italic;
        text-transform: lowercase;
        text-decoration: none;
        margin-top: 100px; }

        header .offcanvas .menu .menu-item a:hover {
          color: #ed0677;
          text-decoration: none; }
   
    header .slider {
      height: 600px; }
      header .slider h2 {
        color: #00aeef;
        font-size: 27px;
        font-weight: 800;
        font-style: italic;
        width: 431px; }
      header .slider p {
        color: white;
        font-size: 16px;
        font-weight: 400;
        width: 431px; }
      header .slider .buttons-app {
        max-width: 166px; }
        header .slider .buttons-app p {
          width: 166px;
          color: white;
          font-size: 18px;
          font-weight: 800;
          font-style: italic; }  

 main.home1 {
   background-image: url("../public/ImagensSafer/images/bg-home-content.png");
   background-position: bottom center;
   background-repeat: no-repeat; }
   main.home1 h3 {
     text-transform: lowercase; }
   main.home p {
     font-size: 18px !important;
     line-height: 21px; }
   main.home1 .boxcontent {
     width: 100%;
     padding-bottom: 500px  ; }



     main.home2 {
      background-image: url("../public//ImagensSafer/images/bg-home-content-en.png");
      background-position: bottom center;
      background-repeat: no-repeat; }
      main.home2 h3 {
        text-transform: lowercase; }
      main.home2 p {
        font-size: 18px !important;
        line-height: 21px; }
      main.home2 .boxcontent {
        width: 100%;
        padding-bottom: 500px  ; }


        main.home3 {
          background-image: url("../src/images/bg-home-contentof.png");
          background-position: bottom center;
          background-repeat: no-repeat; }
          main.home3 h3 {
            text-transform: lowercase; }
          main.home3 p {
            font-size: 18px !important;
            line-height: 21px; }
          main.home3 .boxcontent {
            width: 100%;
            padding-bottom: 500px  ; }


main.safer-motoristas {
  background-image: url("./images/bg-motoristas.png");
  background-position: center bottom;
  background-repeat: no-repeat; }
  main.safer-motoristas p {
    font-size: 16px !important; }
  main.safer-motoristas ul li {
    font-size: 18px !important; }
  main.safer-motoristas .boxcontent {
    width: 705px;
    padding-bottom: 615px; }
  
    main.safer-motoristas {
      background-image: url("./images/bg-motoristas-m.png");
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: contain; }
      main.safer-motoristas p {
        font-size: 20px !important;
        line-height: 20px; }
      main.safer-motoristas ul li {
        font-size: 20px !important;
        line-height: 20px; }
      main.safer-motoristas .boxcontent {
        width: 100%;
        padding-bottom: 300px; }


        main.safer p {
          font-size: 18px !important;
          line-height: 21px; }
        main.safer .boxcontent {
          width: 750px; }

          main.safer p {
            font-size: 20px !important;
            line-height: 20px; }
          main.safer .boxcontent {
            width: 100%; }
            main.safer .boxcontent {
              width: 100%; }

main.safer-empresas {
  background-image: url("./images/bg-empresas.png");
  background-position: center bottom;
  background-repeat: no-repeat; }
  main.safer-empresas p {
    font-size: 16px !important; }
  main.safer-empresas .boxcontent {
    width: 670px;
    padding-bottom: 577px; }

main.safer-empresas {
  background-image: url("./images/bg-empresas-m.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain; }
  main.safer-empresas p {
font-size: 20px !important;
line-height: 20px; }
  main.safer-empresas .boxcontent {
width: 100%;
    padding-bottom: 400px; }

 
 main.safer-viajantes {
background-image: url("./images/bg-viajantes.png");
background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain; }
main.safer-viajantes p {
  font-size: 20px !important;
line-height: 20px; }
main.safer-viajantes .boxcontent {
  width: 100%;
    padding-bottom: 500px}


main.safer-bank {
  background-image: url("./images/bg-bank.png");
  background-position: center bottom;
  background-repeat: no-repeat; }
  main.safer-bank p {
    font-size: 20px !important;
    line-height: 20px; }
  main.safer-bank a.bg-color-2:hover {
color: white !important;
background-color: #98ca45 !important; }
  main.safer-bank .boxcontent {
    width: 100%;
    padding-bottom: 700px; }


main.safer-bank {
background-position: center bottom;
background-repeat: no-repeat;
background-size: contain; }
main.safer-bank p {
  font-size: 20px !important;
  line-height: 20px; }
main.safer-bank a.bg-color-2:hover {
  color: white !important;
  background-color: #98ca45 !important; }
main.safer-bank .boxcontent {
  width: 100%;
  padding-bottom: 700px; }



main.contato {
background-image: url("./images/bg-contato.png");
background-position: center bottom;
background-repeat: no-repeat; }
main.contato form.blue .form-control {
border: solid 1px #00aeef;
border-radius: 15px 15px 15px 15px;
outline: none; }
main.contato form.pink .form-control {
border: solid 1px #ed0677;
border-radius: 15px 15px 15px 15px;
outline: none; }
main.contato #motoristas, main.contato #viajantes {
font-weight: 800; }
main.contato .boxcontent {
width: 690px;
  padding-bottom: 600px; }


  main.contato {
   
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain; }
    main.contato form.blue .form-control {
      border: solid 1px #00aeef;
      border-radius: 15px 15px 15px 15px;
      outline: none; }
    main.contato form.pink .form-control {
      border: solid 1px #ed0677;
      border-radius: 15px 15px 15px 15px;
      outline: none; }
    main.contato #motoristas, main.contato #viajantes {
      font-weight: 800; }
    main.contato .boxcontent {
      width: 100%;
  padding-bottom: 700px;; }

  

  
main h2 {
    color: #ed0677;
    font-size: 36px;
    line-height: 36px;
    font-weight: 900;
    font-style: italic;
    text-transform: lowercase; }
  main h3 {
    color: #ed0677;
    font-size: 27px;
    line-height: 27px;
    font-weight: 800;
    font-style: italic; }
  main p {
    font-size: 18px; }
  main .btn-rounded {
    border-radius: 40px 40px 40px 40px; }

    footer {
      background-color: #003663;
      color: white;
      width: 100% }
      @media (min-width: 1200px) {
        footer .container {
          max-width: 1240px !important; } }
      footer a {
        color: #00aeef;
        font-weight: 700;
        font-size: 30px;
        line-height: 0;
        font-style: italic;
        text-transform: lowercase;
        text-decoration: none; }
        footer a:hover {
          color: #ed0677;
          text-decoration: none; }
      footer .buttons-app p {
        width: 166px;
        color: white;
        font-size: 18px;
        font-weight: 800;
        font-style: italic; }
      footer h3 {
        color: #ed0677;
        font-size: 27px;
        font-weight: 800;
        font-style: italic;
        text-transform: lowercase;
        line-height: 17px; }
      footer .policy a {
        color: white;
        font-size: 18px;
        font-weight: 800;
        font-style: italic; }
        footer .policy a:hover, footer .policy a:active {
          color: #00aeef; }
    
    footer > .container {
      padding-right: 15px;
      padding-left: 15px; }
    
      button{
        width: 50x;
        height: 50px;
        font-size: 25px;
        cursor: pointer;
        background-color:  #00aeef;
        color: #ffffff;
        border: none;
        border-radius: 30px;
        margin: 4px;       
        }


        button:hover {
            background-color:#ed0677;
        }
